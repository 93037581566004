<template>
  <div style="height: 100%">
    <div class="checklist-edit">
      <div class="step-box">
        <head-layout :show-icon="false" head-title="处理进度"></head-layout>
        <el-steps direction="vertical" :active="active" style="margin-top: 30px;align-items: center;height: 50%;">
          <el-step title="上报"></el-step>
          <el-step title="评估"></el-step>
          <el-step title="整改"></el-step>
          <el-step title="验收"></el-step>
        </el-steps>
      </div>
      <div class="form-box">
        <div v-if="prepare" class="">
          <head-layout :head-title="headTitle" @head-cancel="headCancel" :head-btn-options="headBtnOptions"
                       @head-save="headSave(false)" @head-saveBack="headSave(true)"></head-layout>
          <div class="formContentBox">
            <div class="formMain">
              <div style="padding: 0 12px">
                <el-form ref="dataForm" :model="dataForm" label-width="150px" :rules="formType == 'view' ? {} :rules"
                         :disabled="formType == 'view'">
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="事项编号" prop="cvCode">
                        <el-input v-model="dataForm.cvCode" disabled placeholder="事项编号自动生成"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="地点" prop="location">
                        <el-input v-model="dataForm.location" placeholder="请填写地点" :title="dataForm.location">
                          <template slot="append">
                            <div class="el-icon-map-location" style="width: 54px;text-align: center"
                                 @click="openMap()"></div>
                          </template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="报告人" prop="reportUserName">
                        <el-input v-model="dataForm.reportUserName" @focus="handleUserModeOpen()"
                                  placeholder="请选择报告人"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="报告部门" prop="reportDeptName">
                        <el-input v-model="dataForm.reportDeptName" @focus="handleDeptModeOpen()" :title="dataForm.reportDeptName"
                                  placeholder="请选择报告部门"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="报告时间" prop="reportDate">
                        <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" v-model="dataForm.reportDate"
                                        placeholder="请选择报告时间"></el-date-picker>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="描述" prop="cvDesc">
                        <el-input type="textarea" :autosize="{ minRows: 2}" show-word-limit maxlength="225"
                                  v-model="dataForm.cvDesc"
                                  placeholder="请填写描述"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="事项来源" prop="sourceType">
                        <avue-select :props="{
              label: 'dictValue',
              value: 'dictKey',
            }" v-model="dataForm.sourceType" placeholder="请选择事项来源" type="tree"
                                     :dic="HD_SOURCE_TYPE"></avue-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="现场" prop="treeData">
                        <el-upload
                          v-if="formType != 'view'"
                          accept=".jpg,.mp4,.png"
                          style="margin-bottom: 12px;display: flex; align-items: center;"
                          class="upload-demo"
                          action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz-thumbnail/km"
                          :show-file-list="false"
                          :before-upload="checkFileType"
                          :on-success="handleAvatarSuccess"
                          ref="uploadRef"
                          :headers="headers"
                        >
                          <el-button
                            size="small"
                            type="primary"
                            icon="el-icon-upload"
                          >点击上传
                          </el-button>
                        </el-upload>
                        <div class="content">
                          <div
                            v-for="(item, index) in treeData"
                            :key="item.id"
                            class="img-content"
                            @click="getNodeClick(item)"
                          >
                            <img :src="item.thumbnailLink" alt=""/>
                            <i
                              v-if="formType !== 'view'"
                              class="el-icon-circle-close delete-icon"
                              @click.stop="handleRemove(item, index)"
                            ></i>
                          </div>
                        </div>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
          </div>
        </div>
        <div v-if="unassess" class="">
          <head-layout head-title="事项评估"></head-layout>
          <div class="formContentBox">
            <div class="formMain">
              <div style="padding: 0 12px">
                <el-form ref="dataForm" :model="dataForm1" label-width="150px" disabled>
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="事项核实" prop="passFlag">
                        <el-radio v-model="dataForm1.passFlag" label="y">核实通过</el-radio>
                        <el-radio v-model="dataForm1.passFlag" label="n">核实不通过</el-radio>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8" v-if="dataForm1.passFlag == 'n' || dataForm1.passFlag == 'y'">
                      <el-form-item label="评估时间" prop="assessmentTime">
                        <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" v-model="dataForm1.assessmentTime"
                                        placeholder="请选择评估时间"></el-date-picker>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8" v-if="dataForm1.passFlag == 'y'">
                      <el-form-item label="责任部门" prop="respDeptName">
                        <el-input v-model="dataForm1.respDeptName" placeholder="请选择责任部门"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8" v-if="dataForm1.passFlag == 'y'">
                      <el-form-item label="责任人" prop="respUserName">
                        <el-input v-model="dataForm1.respUserName" placeholder="请选择责任人"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="提交整改计划" prop="rectifyPlanFlag">
                        <el-checkbox false-label="n" true-label="y" v-model="dataForm1.rectifyPlanFlag"></el-checkbox>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8" v-if="dataForm1.passFlag == 'y'">
                      <el-form-item label="整改部门" prop="rectifyDeptName">
                        <el-input v-model="dataForm1.rectifyDeptName" placeholder="请选择整改部门"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8" v-if="dataForm1.passFlag == 'y'">
                      <el-form-item label="整改人" prop="sourceType">
                        <el-input v-model="dataForm1.rectifyUserName" placeholder="请选择实际整改人"></el-input>
                      </el-form-item>
                    </el-col>

                  </el-row>
                  <el-row>
                    <el-col :span="8" v-if="dataForm1.passFlag == 'y'">
                      <el-form-item label="验收部门" prop="acceptDeptName">
                        <el-input v-model="dataForm1.acceptDeptName" placeholder="请选择验收部门"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8" v-if="dataForm1.passFlag == 'y'">
                      <el-form-item label="验收人" prop="acceptUserName">
                        <el-input v-model="dataForm1.acceptUserName" placeholder="请选择验收人"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8" v-if="dataForm1.passFlag == 'y'">
                      <el-form-item label="整改期限" prop="rectifyLimitDate">
                        <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" v-model="dataForm1.rectifyLimitDate"
                                        placeholder="请选择整改期限"></el-date-picker>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24" v-if="dataForm1.passFlag == 'y'">
                      <el-form-item label="整改要求" prop="rectifyRequest">
                        <el-input type="textarea" :autosize="{ minRows: 2}" show-word-limit
                                  v-model="dataForm1.rectifyRequest" placeholder="请填写整改要求"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
          </div>
        </div>
        <div v-if="unrectify" class="">
          <head-layout head-title="事项整改"></head-layout>
          <div class="formContentBox">
            <div class="formMain">
              <div style="padding: 0 12px">
                <el-form ref="dataForm" :model="dataForm2" label-width="150px" disabled>
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="完成时间" prop="rectifyDate">
                        <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" v-model="dataForm2.rectifyDate"
                                        placeholder="请选择完成时间"></el-date-picker>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="实际整改人" prop="actualRectifyUserName">
                        <el-input v-model="dataForm2.actualRectifyUserName" placeholder="请选择实际整改人"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="整改措施" prop="remark">
                        <el-input type="textarea" :autosize="{ minRows: 2}" show-word-limit v-model="dataForm2.remark"
                                  placeholder="请填写整改要求"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="整改计划" prop="prjAs">
                        <el-upload
                          :on-preview="handlePreview"
                          disabled
                          class="upload-demo"
                          accept=".rar,.zip,.doc,.docx,.pdf"
                          :file-list="treeData3"></el-upload>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="整改结果" prop="prjAs">
                        <div class="content">
                          <div
                            v-for="(item, index) in treeData4"
                            :key="item.id"
                            class="img-content"
                            @click="getNodeClick(item)"
                          >
                            <img
                              v-if="
                          item.extension == 'jpg' ||
                          item.extension == 'png' ||
                          item.extension == 'image/jpeg' ||
                          item.extension == 'image/png'
                       " :src="item.link"   alt=""
                            />
                            <img
                              v-else
                              src="@/assets/images/dashboard-nodata.png"
                              alt=""
                            />

                          </div>
                        </div>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
          </div>
        </div>
        <div v-if="finished" class="">
          <head-layout head-title="事项验收"></head-layout>
          <div class="formContentBox">
            <div class="formMain">
              <div style="padding: 0 12px">
                <el-form ref="dataForm3" :model="dataForm3" label-width="150px" disabled>
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="验收结果" prop="acceptFlag">
                        <el-radio v-model="dataForm3.acceptFlag" label="y">核实通过</el-radio>
                        <el-radio v-model="dataForm3.acceptFlag" label="n">核实不通过</el-radio>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="验收时间" prop="acceptDate">
                        <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" v-model="dataForm3.acceptDate"
                                        placeholder="请选择完成时间"></el-date-picker>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="实际验收人" prop="actualAcceptUserName">
                        <el-input v-model="dataForm3.actualAcceptUserName"
                                  placeholder="请选择实际验收人"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="验收意见" prop="acceptOpinion">
                        <el-input type="textarea" :autosize="{ minRows: 2}" show-word-limit
                                  v-model="dataForm3.acceptOpinion" placeholder="请填写验收意见"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="验收现场" prop="prjAs">
                        <div class="content">
                          <div
                            v-for="(item, index) in treeData5"
                            :key="item.id"
                            class="img-content"
                            @click="getNodeClick(item)"
                          >
                            <img :src="item.thumbnailLink" alt=""/>
                          </div>
                        </div>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="选择位置" :append-to-body="false" :visible.sync="mapBox" top="7vh">
      <div style="width: 100%">
        <!--        <BaiduMap @changePosition="changePostion" :point="dataForm.location" @clickEvent="click" @getLocalAddress="getLocalAddr"></BaiduMap>-->
        <GaodeMap v-if="mapBox" ref="GaodeMap" @selectAddress="selectAddress" @getLocation="getLocation"></GaodeMap>
      </div>
      <span slot="footer">
        <el-button size="small" @click="celMap">
          {{ $t(`cip.cmn.btn.celBtn`) }}
        </el-button>
        <el-button size="small" type="primary" @click="handleMapSave">
          {{ $t(`cip.cmn.btn.defBtn`) }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog title="人员选择" :visible.sync="deptShow" width="80%" top="8vh">
      <UserDetpDialog ref="UserDetpDialog" @select-data="selectData"></UserDetpDialog>
    </el-dialog>
    <el-dialog title="部门选择" :visible.sync="deptShow1" width="80%">
      <DeptDialog ref="DeptDialog" @select-data="selectData1"></DeptDialog>
    </el-dialog>
    <el-dialog
      v-dialog-drag
      title="附件预览"
      :modal="true"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="showFileDialog"
      width="70%"
    >
      <iframe
        ref="fileIframe"
        :src="fileUrl"
        frameborder="0"
        style="width: 100%; height: 500px"
      ></iframe>
      <span slot="footer">
        <el-button size="small" @click="showFileDialog = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import * as  API from "@/api/civilizedNorm/checklist";
import website from "@/config/website";
import {detail} from "@/api/civilizedNorm/checklist";
import snows from "@/util/snows";
import {dateFormat} from "@/util/date";
import GaodeMap from "@/views/system/attendance/GaodeMap";
import UserDetpDialog from "@/views/components/UserDeptDialog/UserDetpDialog.vue";
import DeptDialog from "@/views/components/UserDeptDialog/DeptDialog.vue";
import {mapGetters} from "vuex";
import {getToken} from "@/util/auth";

export default {
  components: {
    DeptDialog,
    UserDetpDialog,
    GaodeMap
    // BaiduMap
  },
  data() {
    return {
      deptShow: false,
      deptShow1: false,
      showFileDialog: false,
      mapBox: false,
      fileUrl: '',
      respUserShow: false,
      historyShow: false,
      modelDataForm: {},
      modelDataFormImgList: [],
      dataForm1: {},
      dataForm2: {},
      dataForm3: {},
      treeData: [],
      treeData1: [],
      treeData2: [],
      treeData3: [],
      treeData4: [],
      treeData5: [],
      sourceDic: [],
      hd_level: [],
      HD_SOURCE_TYPE: [],
      hd_type: [],
      formType: '',
      headTitle: '',
      // 默认为中材国际大厦经纬度
      mapdata: {
        longitude: '116.481752',
        latitude: '40.01681'
      },
      hdStatus: '',
      dataForm: {
        reportDate: dateFormat(new Date()),
      },
      rules: {
        treeData: [{
          required: true,
          message: '请上传现场图片',
          trigger: 'blur'
        }],
        cvDesc: [{
          required: true,
          message: '描述不能为空',
          trigger: 'blur'
        }],
        reportDate: [{
          required: true,
          message: '报告时间不能为空',
          trigger: 'change'
        }],
        reportUserName: [{
          required: true,
          message: '报告人不能为空',
          trigger: 'blur'
        }],

      },
      tableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      hd_status: [],
      selectionList: [],
      active: 1,
      prepare: false,//草稿
      unassess: false,//待评估
      unrectify: false,//待整改
      finished: false,//完成
    }
  },
  computed: {
    headers: function () {
      return {"Sinoma-Auth": getToken()};
    },
    ...mapGetters([
      'userInfo'
    ]),
    headBtnOptions() {
      let result = [];
      if (['add', 'edit'].includes(this.formType)) {
        result.push({
          label: "保存",
          emit: "head-save",
          type: "button",
          btnOptType: "save",
        });
        result.push({
          label: "提交并返回",
          emit: "head-saveBack",
          type: "button",
          btnOptType: "refer",
        });
      }
      result.push({
        label: "取消",
        emit: "head-cancel",
        type: "button",
        btnOptType: "cancel",
      });
      return result;
    },
  },
  async mounted() {
    this.dataForm.id = this.$route.query.id;
    let type = this.$route.query.type;
    this.formType = type;
    if (type == 'edit') {
      this.headTitle = '事项信息';
      this.getPrjbasicinfoDetail();
    } else if (type == 'view') {
      this.headTitle = '事项信息';
      this.getPrjbasicinfoDetail();
    } else {
      this.$set(this.dataForm, 'organizationId', this.userInfo.dept_id)
      this.$set(this.dataForm, 'organizationName', this.userInfo.deptName)
      this.$set(this.dataForm, 'reportDeptId', this.userInfo.dept_id)
      this.$set(this.dataForm, 'reportDeptName', this.userInfo.deptName)
      this.$set(this.dataForm, 'reportUser', this.userInfo.user_id)
      this.$set(this.dataForm, 'reportUserName', this.userInfo.real_name)
      this.hdStatus = 'prepare'
      this.headTitle = '新增事项信息';
      this.hdStatusShow()
    }
    await this.getCode('HD_SOURCE_TYPE')
    await this.getCode('hd_status')
    await this.getCode('hd_level')
    await this.getCode('hd_type')
  },
  methods: {
    handleRemove(item, index) {
      this.treeData.splice(index, 1);
    },
    selectData(row) {
      this.deptShow = false
      this.dataForm.reportUser = row.id
      this.dataForm.reportUserName = row.realName
      console.log("进行输出人员-------------------", row)
    },
    selectData1(row) {
      this.deptShow1 = false
      this.dataForm.reportDeptId = row.id
      this.dataForm.reportDeptName = row.deptName
      console.log("进行输出人员-------------------", row)
    },
    handleUserModeOpen() {
      this.deptShow = true
    },
    handleDeptModeOpen() {
      this.deptShow1 = true
    },
    handlePreview(file) {
      snows.downloadFile1(file.link, file.name)
    },
    hdStatusShow() {
      if (this.hdStatus == 'prepare') {
        this.prepare = true
        this.active = 1
      } else if (this.hdStatus == 'unassess') {
        this.prepare = true
        // this.unassess = true
        this.active = 1
      } else if (this.hdStatus == 'canceled') {
        this.prepare = true
        this.unassess = true
        this.active = 1
      } else if (this.hdStatus == 'unrectify') {
        this.prepare = true
        this.unassess = true
        // this.unrectify = true
        this.active = 2
      } else if (this.hdStatus == 'unaccept') {
        this.prepare = true
        this.unassess = true
        this.unrectify = true
        // this.finished = true
        this.active = 3
      } else if (this.hdStatus == 'finished') {
        this.prepare = true
        this.unassess = true
        this.unrectify = true
        this.finished = true
        this.active = 4
      }
    },
    async getCode(code) {
      await API.HD_SOURCE_TYPE(code).then(res => {
        this[code] = res.data.data
      })
    },
    checkFileType(file){
      const fileType = file.name.substring(file.name.lastIndexOf('.'));
      const isLt20M = file.size / 1024 / 1024 < 20;
      const isLt5M = file.size / 1024 / 1024 < 5;
      const isJPG = (fileType === '.jpg' ||  fileType == '.png' || fileType == '.mp4');
      if (!isJPG) {
        this.$message.warning('上传图片只能是 .jpg  .png .mp4 格式!');
      }
      if ((fileType === '.jpg' ||  fileType == '.png' ) && !isLt5M) {
        this.$message.warning('上传图片大小不能超过 5MB!');
      }
      if (fileType == '.mp4' && !isLt20M) {
        this.$message.warning('上传文件大小不能超过 20MB!');
      }
      return isJPG && isLt20M && isLt5M;
    },
    handleAvatarSuccess(res, file, fileList) {
      this.treeData.push({
        extension: file.raw.type,
        id: file.response.data.attachId,
        link: file.response.data.link,
        thumbnailLink: file.response.data.thumbnailLink,
        name: file.response.data.originalName,
        originalName: file.response.data.name,
      });
      this.$set(this.dataForm, 'treeData', this.treeData)
      this.$refs.dataForm.validateField('treeData')
    },
    // 根据ID查表单详情
    getPrjbasicinfoDetail() {
      detail({
        id: this.dataForm.id
      }).then((res) => {
        if (res.data.code == 200) {
          this.dataForm = res.data.data;
          this.hdStatus = res.data.data.hdStatus;
          if (res.data.data.cnAssessmentVO) {
            this.dataForm1 = res.data.data.cnAssessmentVO;
          }
          if (res.data.data.cnRectifyVO) {
            this.dataForm2 = res.data.data.cnRectifyVO;
          }
          if (res.data.data.cnAcceptVO) {
            this.dataForm3 = res.data.data.cnAcceptVO;
          }
          this.treeData =this.dataForm.hdUrl ? JSON.parse(this.dataForm.hdUrl) :[]
          this.$set(this.dataForm, 'treeData', this.treeData)
          if (res.data.data.cnAssessmentVO.assessmentUrl) {
            this.treeData1 = JSON.parse(res.data.data.cnAssessmentVO.assessmentUrl)
          }
          if (res.data.data.cnAssessmentVO.punishUrl) {
            this.treeData2 = JSON.parse(res.data.data.cnAssessmentVO.punishUrl)
          }
          if (res.data.data.cnRectifyVO.rectifyPlanUrl) {
            this.treeData3 = JSON.parse(res.data.data.cnRectifyVO.rectifyPlanUrl)
          }
          if (res.data.data.cnRectifyVO.rectifyUrl) {
            this.treeData4 = JSON.parse(res.data.data.cnRectifyVO.rectifyUrl)
          }
          if (res.data.data.cnAcceptVO.acceptUrl) {
            this.treeData5 = JSON.parse(res.data.data.cnAcceptVO.acceptUrl)
          }
          this.hdStatusShow()
        }
      })
    },
    //获取经纬度
    openMap() {
      if (this.formType == 'view') return
      this.mapBox = true
    },
    changePostion(lat, lng) {
      this.mapdata = {
        longitude: lng,
        latitude: lat
      }
    },
    click(lng, lat) {
      this.mapdata = {
        longitude: lng,
        latitude: lat
      }
    },
    getLocalAddr(lat, lng) {
      if (['add'].includes(this.type)) {
        this.mapdata = {
          longitude: lng,
          latitude: lat
        }
      }
    },
    celMap() {
      this.mapBox = false;
      this.$set(this.dataForm, 'location', '');
      this.$set(this.dataForm, 'latitude', '');
    },
    handleMapSave() {
      // 高德地图用
      this.mapBox = false;

      // 百度地图用
      // if(!this.myGeo ){
      //   this.myGeo = new BMap.Geocoder()
      // }
      // let that=this
      // this.myGeo.getLocation(new BMap.Point(this.mapdata.longitude,this.mapdata.latitude), (point) => {
      //   if (point) {
      //     that.$set(that.dataForm,'location',point.address)
      //     that.$set(that.dataForm,'latitude',this.mapdata.longitude+this.mapdata.latitude)
      //     that.mapBox = false
      //   }
      // }, '全国');
    },
    // 高德地图用
    selectAddress(data) {
      this.$set(this.dataForm, 'location', data.address);
      this.$set(this.dataForm, 'latitude', data.lng + ',' + data.lat);
    },
    // 高德地图用
    getLocation(data) {
      this.$set(this.dataForm, 'location', data.address);
      this.$set(this.dataForm, 'latitude', data.lng + ',' + data.lat);
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    // 保存
    headSave(boolean) {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          this.$loading();
          let url = this.dataForm.id ? API.update : API.save
          url({
            taskId:this.$route.query.taskId,
            proType:this.$route.query.proType,
            ...this.dataForm,
            hdStatus: boolean ? 'unassess' : this.dataForm.hdStatus ? this.dataForm.hdStatus : 'prepare',
            hdUrl: JSON.stringify(this.treeData),
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message:boolean ? '提交成功' :"保存成功",
                type: "success",
              });
              if (boolean) {
                this.$router.$avueRouter.closeTag();
                this.$router.back();
              }
              this.dataForm.id = res.data.data;
              this.getPrjbasicinfoDetail();
              this.$loading().close();
            }
          }).catch(err => {
            this.$loading().close();
          })
        }
      });

    },
    getNodeClick(node) {
      this.selectNode = node;
      this.showFile(node);
    },
    showFile(item) {
      this.fileUrl =
        process.env.VUE_APP_BASE_KKFILEURL + "?url=" +
        encodeURIComponent(Base64.encode(item.link));
      this.showFileDialog = true;
      //exls表格预览隐藏打印按钮
      if (process.env.NODE_ENV === "production") {
        const iframe = this.$refs.fileIframe;
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;

        // 在 iframe 内部隐藏元素
        if (iframeDocument) {
          const elementToHide = iframeDocument.getElementById("button-area");
          if (elementToHide) {
            elementToHide.style.display = "none";
          }
        }
      }
    },
  }
}

</script>
<style lang="scss">
.checklist-edit {
  display: flex;
  height: 100%;
  justify-content: space-between;

  .formContentBox {
    padding: 12px;
    height: calc(100% - 76px);
  }

  .el-input-group__append {
    padding: 0;
  }

  .formMain {
    width: 100%;
    overflow: auto;
    height: 100%;
  }

  .step-box {
    background-color: #ffffff;
    width: 125px;
  }

  .form-box {
    height: 100%;
    overflow: scroll;
    background-color: #ffffff;
    width: calc(100% - 135px);
  }

  .formTopic {
    width: 100%;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid rgb(187, 187, 187);
    font-weight: 600 !important;
    font-size: 18px;
  }

  .shuttleBackBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;

    .el-tabs {
      width: 100%;
    }

    .table-box {
      width: calc(100% - 280px);
    }
  }

  .shutleBack {
    width: 33%;
    background: #ffffff;
    border: 1px solid #ebeef5;
    border-radius: 4px;
  }

  .leftRightBtn {
    margin: 211px 12px;
  }

  .shutleTitle {
    width: 100%;
    background-color: #f5f7fa;
    height: 40px;
    line-height: 40px;
    text-indent: 12px;
    border-bottom: 1px solid #ebeef5;
    font-size: 16px;
  }

  .shutContent {
    padding: 6px;
    height: 400px;
    overflow: auto;
  }

  .deptBox {
    line-height: 40px;
    color: #333333;
    border-bottom: 1px solid #ebeef5;
    font-size: 13px;
    display: flex;
  }

  .unitBox {
    width: 100%;
    display: flex;
    background-color: #f5f7fa;
    border-bottom: 1px solid #ebeef5;
  }

  .unitTitle {
    height: 40px;
    line-height: 40px;
    padding: 0 12px;
    font-size: 16px;
    cursor: pointer;
    border-right: 1px solid #ebeef5;
  }

  .content {
    display: flex;
    align-items: center;

    .img-content {
      width: 188px;
      height: 100px;
      padding: 3px;
      position: relative;
      border: 1px solid #ccc;
      border-radius: 3px;
      margin-right: 12px;

      .delete-icon {
        position: absolute;
        top: -6px;
        right: -6px;
        font-size: 16px;
        color: red;
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

  }
}
</style>
